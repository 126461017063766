<template>
    <div class="main-navigation">
        <div class="container-fluid h-100 px-lg-5">
            <div class="main-navigation--inner">
                <a href="/">
                    <img src="https://assets.scola.id/newwebsite/images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo" width="96">
                </a>
                <div class="main-nav">
                    <ul class="navbar-nav horizontal mr-3 nav-black navbar-style-2">
                        <li class="nav-item desktop-only dropdown">
                            <div class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Solusi Scola<span class="fe fe-chevron-down ml-2"></span></div>
                            <div class="dropdown-menu dropdown-menu-center dropdown-menu-full shadow shadow-lg card-3d-bottom" aria-labelledby="dropdownMenuButton">
                                <div class="d-block p-2">
                                    <router-link to="solusi-scola-sekolah" class="d-block mb-3 dropdown-solusi-scola">
                                        <div class="row align-items-center">
                                            <div class="col-4">
                                                <img alt="Scola student illustration" data-src="https://assets.scola.id/images/scola-student-1.svg" class="w-100 lozad">
                                            </div>
                                            <div class="col-8 pl-2">
                                                <h3 class="d-block font-weight-bolder mb-1">Solusi Scola untuk <span class="underline">Sekolah</span></h3>
                                                <div>Pelajari <span class="fe fe-arrow-right ml-2"></span></div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="solusi-scola-guru" class="d-block mb-3 dropdown-solusi-scola">
                                        <div class="row align-items-center">
                                            <div class="col-4">
                                                <img alt="Scola teacher illustration" data-src="https://assets.scola.id/images/scola-teacher-1.svg" class="w-100 lozad">
                                            </div>
                                            <div class="col-8 pl-2">
                                                <h3 class="d-block font-weight-bolder mb-1">Solusi Scola untuk <span class="underline underline-2">Guru</span></h3>
                                                <div>Pelajari <span class="fe fe-arrow-right ml-2"></span></div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="solusi-scola-orangtua" class="d-block dropdown-solusi-scola">
                                        <div class="row align-items-center">
                                            <div class="col-4">
                                                <img alt="Scola parent illustration" data-src="images/scola-parent-1.svg" class="w-100 lozad">
                                            </div>
                                            <div class="col-8 pl-2">
                                                <h3 class="d-block font-weight-bolder mb-1">Solusi Scola untuk <span class="underline underline-3">Orang Tua</span></h3>
                                                <div>Pelajari <span class="fe fe-arrow-right ml-2"></span></div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="nav-item desktop-only hide-991">
                            <router-link class="nav-link" :active-class="activeClass == 'home' ? 'active-link':''" to="/">Beranda</router-link>
                        </li> -->
                        <li class="nav-item desktop-only hide-991">
                            <router-link class="nav-link" :active-class="activeClass == 'program' ? 'active-link':''" to="/program">Program</router-link>
                        </li>
                        <li class="nav-item desktop-only">
                            <router-link class="nav-link" :active-class="activeClass == 'best-features' ? 'active-link':''" to="/fitur-andalan">Fitur Andalan</router-link>
                        </li>
                        <li class="nav-item desktop-only">
                            <router-link class="nav-link" :active-class="activeClass == 'about' ? 'active-link':''" to="/about">Tentang Kami</router-link>
                        </li>
                        <li class="nav-item desktop-only">
                            <router-link class="nav-link" :active-class="activeClass == 'content' ? 'active-link':''" to="/konten">Konten</router-link>
                        </li>
                        <li class="nav-item desktop-only">
                            <router-link class="nav-link" :active-class="activeClass == 'blog' ? 'active-link':''" to="/blog">Berita Terkini</router-link>
                        </li>
                        <!-- <li class="nav-item desktop-only">
                            <a class="nav-link" href="https://blog.scola.id/">Artikel</a>
                        </li> -->
                        <li class="nav-item desktop-only">
                            <router-link class="nav-link" :active-class="activeClass == 'contact' ? 'active-link':''" to="/contact">Kontak</router-link>
                        </li>
                    </ul>
                    <router-link to="register" class="btn btn-primary mr-3 mr-md-0">Daftar Sekarang</router-link>&nbsp;
                    <div class="dropdown mobile-only">
                        <button type="button" class="btn btn-white px-3" id="navbarDropdown" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="fe fe-menu"></span><span class="ml-1 fe fe-chevron-down text-muted"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right shadow" aria-labelledby="navbarDropdown">
                            <!-- <a class="dropdown-item" href="/">Beranda</a> -->
                            <router-link class="dropdown-item" to="solusi-scola-sekolah">Solusi Sekolah</router-link>
                            <router-link class="dropdown-item" to="solusi-scola-guru">Solusi Guru</router-link>
                            <router-link class="dropdown-item" to="solusi-scola-orangtua">Orang Tua</router-link>
                            <hr class="dropdown-divider">
                            <router-link class="dropdown-item" to="program">Program</router-link>
                            <router-link class="dropdown-item" to="fitur-andalan">Fitur Andalan</router-link>
                            <hr class="dropdown-divider">
                            <router-link class="dropdown-item" to="about">Tentang Kami</router-link>
                            <router-link class="dropdown-item" to="konten">Konten Scola LMS</router-link>
                            <router-link class="dropdown-item" to="blog">Berita Terkini</router-link>
                            <router-link class="dropdown-item" to="contact">Kontak</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
    export default {
        props : {
            activeClass : {
                type : String,
                default : 'home'
            }
        },
        mounted() {
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>