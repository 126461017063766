<template>
    <div class="section section-lg pb-5 section-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12 px-lg-5 pb-4">
                    <div class="footer-group">
                        <div class="footer-item--group">
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Scola</li>
                                    <li><router-link to="program">Program</router-link></li>
                                    <li><router-link to="about">Tentang Kami</router-link></li>
                                    <li><router-link to="about#PenggunaScola">Pengguna Scola</router-link></li>
                                    <li><router-link to="about#ProsesPembelajaran">Proses Pembelajaran</router-link></li>
                                    <li><router-link to="about#ProsesBergabung">Proses Bergabung</router-link></li>
                                </ul>
                            </div>
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Solusi Scola</li>
                                    <li><router-link to="solusi-scola-sekolah">Solusi untuk Sekolah</router-link></li>
                                    <li><router-link to="solusi-scola-guru">Solusi untuk Guru</router-link></li>
                                    <li><router-link to="solusi-scola-orangtua">Solusi untuk Orang Tua</router-link></li>
                                </ul>
                            </div>
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Fitur</li>
                                    <li><router-link to="fitur-andalan">Kelas Online</router-link></li>
                                    <li><router-link to="virtual-class">Virtual Class</router-link></li>
                                    <li><router-link to="ujian-online">Ujian Online</router-link></li>
                                    <li><router-link to="manajemen-sistem">Sistem Manajemen</router-link></li>
                                </ul>
                            </div>
                            <div class="footer-item">
                                <ul class="footer-item--list">
                                    <li class="title">Bantuan</li>
                                    <li><a href="https://api.whatsapp.com/send?phone=628562151145" target="_blank" rel="noopener noreferrer">Pemasaran &
                                            Kemitraan</a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=6281318667468" target="_blank" rel="noopener noreferrer">Layanan &amp; Dukungan Teknis</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 px-lg-5">
                    <hr>
                    <div class="d-block text-xs pt-3">
                        <div class="d-block text-muted mb-md-2 mb-4">
                            Copyright © 2010 - 2020 Scola Learning Management System. All Rights Reserved.
                        </div>
                        <div class="d-flex d-flex-mobile">
                            <router-link to="privacy-policy" class="mr-3 text-muted mb-md-0 mb-2">Kebijakan Privasi</router-link>
                            <router-link to="terms-of-service" class="mr-3 text-muted mb-md-0 mb-2">Ketentuan Penggunaan</router-link>
                            <router-link to="security-disclosure" class="mr-3 text-muted mb-md-0 mb-4">Security Disclosure</router-link>
                            <span class="withlove" style="margin-left:auto">Made with ❤ dari Indonesia</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>
