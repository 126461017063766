<template>
    <!-- More Features -->
    <div class="section section-lg bg-lighter card-style-bottom-left">
        <div class="container mb-5">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center px-lg-5">
                    <div class="title-lg font-weight-bolder mb-4 px-5 px-md-0">Sekolah Pengguna</div>
                    <div class="grid-feature feature-slider school-slider">
                        <!-- <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow="5" :autoplay="true" :autoplaySpeed="2000"> -->
                        <VueSlickCarousel v-bind="settings">
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-1.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">Yayasan Santo Aloysius</div>
                                    <p class="text-xs m-0">Kota Bandung</p>
                                </div>
                            </div>
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-2.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">Al Azhar Syifa Budi</div>
                                    <p class="text-xs m-0">Kabupaten Bandung Barat</p>
                                </div>
                            </div>
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-3.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">Yayasan BPK Penabur</div>
                                    <p class="text-xs m-0">Kota Bandung & Bogor</p>
                                </div>
                            </div>
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-4.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">Al Syukro Universal</div>
                                    <p class="text-xs m-0">Kota Tangerang Selatan</p>
                                </div>
                            </div>
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-5.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">SMK 11</div>
                                    <p class="text-xs m-0">Kota Bandung</p>
                                </div>
                            </div>
                            <div class="grid-feature--item grid-feature--item-3">
                                <div class="grid-inner shadow-sm">
                                    <div class="grid-icon grid-icon-lg"><img src="https://assets.scola.id/newwebsite/images/logo-sekolah-6.png" alt="school logo">
                                    </div>
                                    <div class="grid-title text-sm font-weight-bolder">SMP Paramarta Unggulan</div>
                                    <p class="text-xs m-0">Kota Tangerang Selatan</p>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
                <div class="d-none col-md-12 px-lg-5">
                    <div class="card shadow-none bg-dark text-white mt-5">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between center-vertical-sm">
                                <div class="text-left text-center-sm">
                                    <h2 class="mb-4 mb-md-1">Ingin wujudkan sekolah masa depan anda? Gabung sekarang!
                                    </h2>
                                    <p class="m-0 text-sm"><span class="text-warning">Gratis.</span> Masa coba selama 30 hari.</p>
                                </div>
                                <div class="mt-4 mt-lg-0 d-block-mobile">
                                    <router-link to="register"
                                        class="btn btn-primary btn-primary-3d btn-push pl-5 pr-5 d-block-mobile">Formulir
                                        Pendaftaran</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  // Slck
  import VueSlickCarousel from 'vue-slick-carousel'

  export default {
    name: 'SchoolList',
    components: {
      VueSlickCarousel
    },
    data() {
        return {
            settings: {
                arrows: true,
                dots: true,
                slidesToShow: 5,
                autoplay: true,
                autoplaySpeed: 3000,
                responsive: [
                    {
                    breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                    breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            }
        }
    }
  }
</script>