import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/fitur-andalan',
    name: 'fitur-andalan',
    component: () => import( /* webpackChunkName: "about" */ '../views/Feature.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( /* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/konten',
    name: 'konten',
    component: () => import( /* webpackChunkName: "about" */ '../views/Content.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/virtual-class',
    name: 'virtual-class',
    component: () => import( /* webpackChunkName: "about" */ '../views/VirtualClass.vue')
  },
  {
    path: '/ujian-online',
    name: 'ujian-online',
    component: () => import( /* webpackChunkName: "about" */ '../views/UjianOnline.vue')
  },
  {
    path: '/manajemen-sistem',
    name: 'manajemen-sistem',
    component: () => import( /* webpackChunkName: "about" */ '../views/ManajemenSistem.vue')
  },
  {
    path: '/register-success',
    name: 'register-success',
    component: () => import( /* webpackChunkName: "about" */ '../views/RegisterSuccess.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import( /* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import( /* webpackChunkName: "about" */ '../views/Blog.vue')
  },
  {
    path: '/blog-read',
    name: 'blog-read',
    component: () => import( /* webpackChunkName: "about" */ '../views/BlogRead.vue')
  },
  {
    path: '/program',
    name: 'program',
    component: () => import( /* webpackChunkName: "about" */ '../views/Program.vue')
  },
  {
    path: '/solusi-scola-guru',
    name: 'solusi-scola-guru',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiGuru.vue')
  },
  {
    path: '/solusi-scola-sekolah',
    name: 'solusi-scola-sekolah',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiSekolah.vue')
  },
  {
    path: '/solusi-scola-orangtua',
    name: 'solusi-scola-orangtua',
    component: () => import( /* webpackChunkName: "about" */ '../views/SolusiOrangTua.vue')
  },
  {
    path: '/security-disclosure',
    name: 'security-disclosure',
    component: () => import( /* webpackChunkName: "about" */ '../views/SecurityDisclosure.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import( /* webpackChunkName: "about" */ '../views/TermsOfService.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
  }})

export default router