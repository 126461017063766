<template>
  <div class="d-block">
    <header-components></header-components>

    <div class="popup-intro" v-if="!isHidden">
      <div class="popup-content">
        <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
          <button v-on:click="isHidden = !isHidden" type="button" class="btn btn-link">Tutup</button>
        </div>
        <div class="d-block p-md-5 p-3 text-center pt-0">
          
          <img alt="Scola Owl Teaching" data-src="https://assets.scola.id/images/owl-teacher.svg" width="160" class="d-inline-block mb-4 lozad">
          <h1 class="mb-2">Apakah anda seorang Guru?</h1>
          <p class="mb-2">Ikuti program <span class="underline underline-1"><b>Kelas Digital*</b></span> dari <span class="underline underline-2"><b>Scola</b></span> untuk sistem belajar mengajar masa depan.</p>
          
          <button class="btn btn-link" data-toggle="modal" data-target="#kelasDigital">Lebih lengkap mengenai Kelas Digital</button>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScVwRtufiuwBUtF7GvSPL_e3ZYwi0YHrcqO-G_HjlTMT53Wqg/viewform" target="_blank" rel="noreferrer" class="btn btn-primary btn-block mb-2">Daftar Sekarang</a>
          
          <div class="d-block mb-4 mb-md-0">
            <div class="text-xs"><span class="text-danger">*</span>Dapatkan free penggunaan Kelas Digital</div>
            <div class="text-xs"><span class="text-danger">*</span>Terbatas untuk 50 guru</div>
            <div class="text-xs"><span class="text-danger">*</span>Syarat & ketentuan berlaku</div>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal Kelas Digital -->
    <div class="modal" id="kelasDigital" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background:transparent;">
                <div class="card border-0 mb-0" style="overflow:hidden;">
                    <img src="/images/banner-kelas-digital.jpg" class="w-100 border-bottom">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h1 class="m-0">Kelas Digital</h1>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScVwRtufiuwBUtF7GvSPL_e3ZYwi0YHrcqO-G_HjlTMT53Wqg/viewform" rel="noreferrer" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program Kelas Digital</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lined lined-yellow">
                                    <p class="text-xs">
                                        Program Kelas Digital merupakan terobosan terbaru dari Scola LMS yang bertujuan dalam membantu tenaga pengajar terkait kendala susahnya mendapatkan platform pembelajaran yang sesuai dengan kondisi saat ini, Maka untuk meningkatan metode pendidikan kami berusaha memberikan platform pendidikan yang canggih dan praktis serta di kembangkan berdasarkan hasil riset dari guru di Indonesia.
                                    </p>
                                    <p class="text-xs">
                                        Kelas Digital dirancang untuk mempermudah pekerjaan guru, menyiapkan kelas belajar digital yang efektif hingga solusi terbaik bagi partner mengajar kepada siswa.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <vsa-list>
                            <!-- Here you can use v-for to loop through items  -->
                            <vsa-item>
                                <vsa-heading>
                                    Keuntungan mengunakan Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Penggunaan Kelas Digital selama satu semester</li>
                                        <li>Menjadi Guru Partnership</li>
                                        <li>Program pelatihan</li>
                                        <li>E-Sertifikat untuk guru digital</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Syarat bergabung dengan Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Digunakan oleh tenaga pendidik dalam instansi pendidikan (Guru dan Dosen)</li>
                                        <li>Terbatas untuk 50 guru hingga awal tahun ajar</li>
                                        <li>Akses diberikan untuk 1 guru 1 sekolah</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Alur bergabung Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <img src="/images/alur-bergabung-kelas-digital.png" class="w-100">
                                </vsa-content>
                            </vsa-item>
                        </vsa-list>
                        <div class="d-flex align-items-center justify-content-end mt-3">
                          <button type="button" class="btn btn-link btn-sm" data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--[ Slider - Glide JS ]-->
    <div class="home-banner--slider">
      <VueSlickCarousel :arrows="true" :dots="true">
        <div class="home-banner">
          <div class="container px-lg-5">
            <div class="row">
              <div class="col-lg-5 col-md-12 text-center-md">
                <div class="home-banner--text">
                  <h1 class="mb-4 font-weight-bolder">Wujudkan sekolah masa depan Anda bersama Scola LMS</h1>
                  <h2 class="mb-5 font-weight-normal lined lined-yellow">Membantu <b>sekolah</b> siap ke era pendidikan
                    digital dengan didukung oleh sistem manajemen pembelajaran terbaik.</h2>
                  <div class="d-flex align-items-center center-md center-y-sm">
                    <a href="register"
                      class="btn btn-primary mr-2 pl-5 pr-5  btn-primary-3d btn-push mb-4 mb-md-0">Daftar sekarang</a>
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_1ca38d8d41d3cc273c07839fe0035c6d824982a017156dac65277be9505ebb9d20200929094911.mp4" data-lity class="btn btn-link text-white">Tonton video kami<span
                        class="ml-2 fe fe-play-circle"></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="d-block bg-gradient-2">
      <div class="py-4">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8 mb-md-0 mb-3">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 mr-3">
                  <img src="/images/class-icon-2.png" height="46">
                </div>
                <div>
                  <div class="h2 text-white m-0">Program Scola</div>
                  <div class="d-block text-white text-sm m-0">Jelajahi program terbaru dari Scola untuk dukung pembelajaran digital di sekolah!</div>
                  <div class="d-block mt-2 small text-white">4 program tersedia</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-right">
              <a href="program" class="btn btn-white border-0 px-4">Lihat Program</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <img data-src="https://assets.scola.id/newwebsite/images/rocket.png" alt="Rocket image" class="rocket lozad"> -->

    <!-- Quick Link / Temporary Hidden -->
    <div class="d-none py-4 bg-lighter border-bottom quick-link">
      <div class="container">
        <div class="row">
          <div class="col-md-12 px-lg-5">
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex link-list text-sm font-weight-bolder">
                <a href="#FiturKelasOnline" class="mr-5 text-primary">Kelas Online</a>
                <a href="#FiturVirtualClass" class="mr-5 text-primary">Virtual Class</a>
                <a href="#FiturUjianOnline" class="mr-5 text-primary">Ujian Online</a>
                <a href="#FiturManajemenSistem" class="text-primary">Manajemen Sistem</a>
                <!-- <a href="#faq" class="mr-5">FAQ</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Section -->
    <div class="section section-xl bg-image bg-image-clear bg-primary card-style-bottom-left card-3d-bottom" style="background-image:url('images/banner-bg-1.jpg');">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 pr-md-5 mb-md-0 mb-5">
            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_6210c6a114ffe29c9ef9b89cbb5d1a4d5f347a4c87c66c9c0bc1c59a066245dc20210203105953.mp4" data-lity>
              <div class="video-box shadow" style="background-image:url('images/banner-testimoni-scola-5.png');">
                <span class="fe fe-play-circle m-0"></span>
              </div>
            </a>
          </div>
          <div class="col-md-5 text-center-sm">
            <div class="font-weight-bolder text-xlg mb-3 mb-md-4 text-white"><b>Waktunya Beralih ke Era Pendidikan Digital</b></div>
            <p class="mb-4 lined lined-yellow text-white">Membantu sekolah siap di era pendidikan digital dengan sistem manajemen pembelajaran terbaik.</p>
            <div class="d-block">
              <router-link to="about" class="btn btn-light px-4 mb-2">Tentang Scola LMS</router-link><br>
              <router-link to="register" class="text-white small"><span class="fe fe-arrow-right mr-2"></span>Gratis masa coba selama 30 hari.</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Intro Action #1 / Temporary Hidden -->
    <div class="d-none section section-xl">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-10 text-center">
            <div class="title-lg font-weight-bolder mb-4"> Pengguna Scola LMS </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 text-center px-lg-4 mb-5 mb-lg-0">
            <img data-src="https://assets.scola.id/newwebsite/images/ilust-2.png" alt="parent image" width="160" class="lozad d-inline-block mb-4">
            <div class="small ">Orang Tua</div>
            <div class="font-weight-bolder text-md mb-2">Pantau perkembangan hasil belajar anak</div>
            <p class="text-sm">Tingkatkan prestasi belajar anak dengan mengetahui kecerdasan dan bakatnya.</p>
          </div>
          <div class="col-lg-3 col-md-6 text-center px-lg-4 mb-5 mb-lg-0">
            <img data-src="https://assets.scola.id/newwebsite/images/ilust-1.png" alt="student image" width="160" class="lozad d-inline-block mb-4">
            <div class="small">Siswa</div>
            <div class="font-weight-bolder text-md mb-2">Siswa dapat belajar dengan mandiri</div>
            <p class="text-sm">Kemudahan proses pembelajaran dan tersedianya bahan pembelajaran.</p>
          </div>
          <div class="col-lg-3 col-md-6 text-center px-lg-4 mb-5 mb-lg-0">
            <img data-src="https://assets.scola.id/newwebsite/images/ilust-3.png" alt="school image" width="160" class="lozad d-inline-block mb-4">
            <div class="small ">Sekolah</div>
            <div class="font-weight-bolder text-md mb-2">Mengelola perkembangan & aktifitas sekolah</div>
            <p class="text-sm">Melihat keaktifan siswa maupun guru dalam proses pembelajaran dengan mudah dan terukur.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 text-center px-lg-4 mb-5 mb-lg-0">
            <img data-src="https://assets.scola.id/newwebsite/images/ilust-4.png" alt="teacher image" width="160" class="lozad d-inline-block mb-4">
            <div class="small ">Guru</div>
            <div class="font-weight-bolder text-md mb-2">Mengamati proses belajar dengan mudah</div>
            <p class="text-sm">Memudahkan guru untuk melihat dan memonitor keaktifan siswa dalam proses pembelajaran.
            </p>
          </div>
          <div class="col-md-12 text-center mt-4">
            <router-link to="about" class="btn btn-outline-primary">Lebih lanjut tentang Scola LMS<span
                class="fe fe-arrow-right ml-2"></span></router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Solusi Scola -->
    <div class="section section-xl pb-5">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-10 text-center">
            <div class="title-lg font-weight-bolder mb-5"> Solusi Kami untuk <span class="underline underline-1 underline-lg">Sekolah Anda</span>, <span class="underline underline-2 underline-lg">Guru</span> & <span class="underline underline-3 underline-lg">Orang Tua</span></div>
            <div class="row justify-content-center">

              <div class="col-lg-4 col-md-6 col-6">
                <router-link to="solusi-scola-sekolah" class="solusi-scola">
                  <img alt="Scola student illustration" data-src="https://assets.scola.id/images/scola-student-1.svg" class="w-100 lozad">
                  <div class="d-flex border-bottom py-3 px-2 justify-content-between mt-2">
                    <div>Scola untuk Sekolah</div>
                    <div><span class="fe fe-arrow-right m-0"></span></div>
                  </div>
                </router-link>
              </div>
              
              <div class="col-lg-4 col-md-6 mb-md-0 mb-3 col-6">
                <router-link to="solusi-scola-guru" class="solusi-scola">
                  <img alt="Scola teacher illustration" data-src="https://assets.scola.id/images/scola-teacher-1.svg" class="w-100 lozad">
                  <div class="d-flex border-bottom py-3 px-2 justify-content-between mt-2">
                    <div>Scola untuk Guru</div>
                    <div><span class="fe fe-arrow-right m-0"></span></div>
                  </div>
                </router-link>
              </div>

              <div class="col-lg-4 col-md-6 mb-md-0 mb-3 col-6">
                <router-link to="solusi-scola-orangtua" class="solusi-scola">
                  <img alt="Scola parent illustration" data-src="https://assets.scola.id/images/scola-parent-1.svg" class="w-100 lozad">
                  <div class="d-flex border-bottom py-3 px-2 justify-content-between mt-2">
                    <div>Scola untuk Orang Tua</div>
                    <div><span class="fe fe-arrow-right m-0"></span></div>
                  </div>
                </router-link>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ####### Scola Punchline ####### -->
    <div class="d-none section-two-side">
      <div class="lozad section-item section-item-bg" data-background-image="https://assets.scola.id/newwebsite/images/pexels-photo-4145032.jpeg"></div>
      <div class="section-item section-item-text bg-primary text-white">
        <div class="d-block pr-md-5 pr-0 ">
          <div class="font-weight-bolder text-xlg mb-5"><b>Tingkatkan kualitas proses pembelajaran di sekolah anda!</b>
          </div>
          <p class="mb-5 text-sm lined lined-yellow"> Kami membantu sekolah anda untuk siap menjadi sekolah masa depan
            di era pendidikan digital. Kami merancang sistem pembelajaran online yang mudah digunakan, menyediakan
            konten-konten e-learning sesuai kurikulum terbaru dan mengunakan metode pembelajaran yang menarik.</p>
          <a href="#OurFeature" class="btn btn-sm btn-outline-white">Lihat fitur terbaik Scola<span
              class="fe fe-arrow-down ml-2"></span></a>
        </div>
      </div>
    </div>
    <div class="d-none bg-primary-gradient" style="height: 12px;"></div>

    <feature-list-components></feature-list-components>

    <div class="section pb-lg-0 pb-5">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-7 col-md-12 mb-lg-0 mb-5 text-center">
            <img src="/images/team-impelementator-scola.png" class="w-100">
          </div>
          <div class="col-lg-5 col-md-12 mb-0 mb-lg-5">
            <div class="text-sm font-weight-bolder text-primary mb-3">TIM PENDAMPING</div>
            <div class="font-weight-bolder text-lg mb-3"><b>Tim implementator dan tim pendamping kami siap untuk membantu anda</b>
            </div>
            <p>Tim implementator dan tim pendamping membantu anda dalam proses pengenalan, implementasi dan pendamping produk sehingga semua user dengan mudah dan terbantu mendapatkan solusi jika ada kendala.</p>
          </div>
        </div>
      </div>
    </div>

    <school-list-components></school-list-components>
    <cta-components></cta-components>
    <footer-components></footer-components>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import FeatureList from '@/components/FeatureList.vue'
  import SchoolList from '@/components/SchoolList.vue'
  import Cta from '@/components/Cta.vue'
  import lozad from 'lozad';
  import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
    } from 'vue-simple-accordion';

  // Slck
  import VueSlickCarousel from 'vue-slick-carousel'
  // optional style for arrows & dots
  // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default {
    name: 'Home',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'feature-list-components': FeatureList,
      'school-list-components': SchoolList,
      'cta-components': Cta,
      VueSlickCarousel,
      VsaList,
      VsaItem,
      VsaHeading,
      VsaContent
    },
    data () {
        return {
            isHidden: true
        }
    },
    metaInfo: {
      title: 'Beranda',
      titleTemplate: 'Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
      scrollFix: function(hashbang)
      {
          location.hash = hashbang;

          // remove fragment as much as it can go without adding an entry in browser history:
          window.location.replace("#");

          // slice off the remaining '#' in HTML5:    
          if (typeof window.history.replaceState == 'function') {
              history.replaceState({}, '', window.location.href.slice(0, -1));
          }

      }
    }
  }
</script>
