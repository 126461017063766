<template>
    <!-- ####### Scola Feature ####### -->
    <div id="OurFeature" class="section section-lg card-style-top-right">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <div class="title-lg font-weight-bolder mb-3">Beragam Fitur Pembelajaran Online Dalam Satu Sentuhan</div>
            <p class="mb-4">Atur semua kebutuhan pembelajaran online dengan Scola LMS, untuk nikmati kemudahan belajar di era pendidikan digital.</p>
          </div>
        </div>
        <div class="feature-slider">
          <VueSlickCarousel :arrows="true" :dots="true" :adaptiveHeight="true">
            <!-- Kelas Online -->
            <div id="FiturKelasOnline" class="section text-center-md">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 px-lg-5 mb-5 mb-lg-0">
                    <div class="feature-img-1">
                      <img data-src="https://assets.scola.id/images/feature-kelas-online.svg" alt="Feature 1" class="w-100 lozad">
                      <img alt="Floating classroom card illustration" data-src="https://assets.scola.id/images/feature-kelas-online-addon-1.svg" class="img-animate lozad">
                    </div>
                  </div>
                  <div class="col-md-6 px-lg-5">
                    <div class="d-flex align-items-center mb-3 center-md">
                      <div class="badge badge-warning mr-3">Populer</div>
                      <div class="text-sm font-weight-bolder text-primary">KELAS ONLINE</div>
                    </div>
                    <h1 class="title-md mb-3">Kelas online memudahkan proses belajar mengajar antara guru dan siswa.</h1>
                    <p class="mb-4 lined lined-yellow">Fitur yang memberikan kemudahan pada guru maupun siswa, untuk belajar mengajar dengan materi yang menarik dan penilaian hasil belajar yang praktis.</p>
                    <div class="d-block mb-3">
                      <div class="row justify-content-center">
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li><span class="fe fe-check-circle mr-2"></span>Materi Belajar</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Tugas</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Kuis & Ujian</li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li><span class="fe fe-check-circle mr-2"></span>Feedback</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Pooling</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Remedial</li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li><span class="fe fe-check-circle mr-2"></span>Diskusi</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Analitik</li>
                            <li><span class="fe fe-check-circle mr-2"></span>Laporan</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <router-link to="fitur-andalan" class="btn btn-primary btn-primary-3d btn-push text-xs d-block-mobile">Pelajari
                      Kelas Online <span class="fe fe-arrow-right ml-2"></span></router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Ujian Online -->
            <div id="FiturUjianOnline" class="section text-center-md">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 px-lg-5 mb-5 mb-lg-0">
                    <div class="feature-img-2">
                      <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-new.svg" alt="Feature 2" class="w-100 lozad">
                      <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-1-new.svg" class="img-animate lozad">
                      <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-2-new.svg" class="img-animate-2 lozad">
                    </div>
                  </div>
                  <div class="col-md-6 px-lg-5">
                    <div class="d-flex align-items-center mb-3 center-md">
                      <div class="badge badge-warning mr-3">Populer</div>
                      <div class="text-sm font-weight-bolder text-primary">UJIAN ONLINE</div>
                    </div>
                    <h1 class="title-md mb-3">Kini anda dapat melakukan uji kemampuan siswa dengan mudah!</h1>
                    <p class="mb-4 lined lined-yellow">Ujian online merupakan fitur untuk memudahkan guru dalam mengelola
                      ujian dan siswa dalam mengerjakan ujian secara online.</p>
                    <div class="d-block mb-3">
                      <div class="row justify-content-center">
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Tipe Soal</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Penilaian</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Pengaturan Ujian</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Report Otomatis</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Konfirmasi Ujian</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4"></div>
                      </div>
                    </div>
                    <router-link to="ujian-online" class="btn btn-primary btn-primary-3d btn-push text-xs d-block-mobile">Pelajari
                      Ujian Online<span class="fe fe-arrow-right ml-2"></span></router-link>
                  </div>

                </div>
              </div>
            </div>
            <!-- Virtual Class -->
            <div id="FiturVirtualClass" class="section text-center-md">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 px-lg-5 mb-5 mb-lg-0">
                    <div class="feature-img-3">
                      <img data-src="https://assets.scola.id/images/feature-virtual-class.jpg" class="w-100 lozad">
                      <img data-src="https://assets.scola.id/newwebsite/images/feature-virtual-class-addon-1-new.svg" class="img-animate lozad">
                      <img data-src="https://assets.scola.id/images/feature-virtual-class-foreground.png" class="w-100 img-foreground lozad">
                    </div>
                  </div>
                  <div class="col-md-6 px-lg-5">
                    <div class="d-flex align-items-center mb-3 center-md">
                      <div class="badge badge-warning mr-3">Populer</div>
                      <div class="text-sm font-weight-bolder text-primary">KELAS INTERAKTIF</div>
                    </div>
                    <h1 class="title-md mb-3">Tatap muka online menjadi lebih mudah dengan kelas interaktif.</h1>
                    <p class="mb-4 lined lined-yellow">Mendukung proses pembelajaran virtual atau bimbingan yang lebih fokus dan interaktif.</p>
                    <div class="d-block mb-3">
                      <div class="row justify-content-center">
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Chating</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Webcam</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Share Screen</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Breakout Room</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Pengaturan Virtual Class</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4"></div>
                      </div>
                    </div>
                    <router-link to="virtual-class"
                      class="btn btn-primary btn-primary-3d btn-push text-xs d-block-mobile">Pelajari Virtual Class<span
                        class="fe fe-arrow-right ml-2"></span></router-link>
                  </div>

                </div>
              </div>
            </div>
            <!-- Manajemen Sistem -->
            <div id="FiturManajemenSistem" class="section text-center-md">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 px-lg-5 mb-5 mb-lg-0">
                    <img data-src="https://assets.scola.id/newwebsite/images/feature-manajemen-new.svg" class="w-100 lozad">
                  </div>
                  <div class="col-md-6 px-lg-5">
                    <div class="d-flex align-items-center mb-3 center-md">
                      <div class="badge badge-warning mr-3">Populer</div>
                      <div class="text-sm font-weight-bolder text-primary">SISTEM MANAJEMEN</div>
                    </div>
                    <h1 class="title-md mb-3">Mudah mengelola sistem pembelajaran sekolah</h1>
                    <p class="mb-4 lined lined-yellow">Sekolah dapat dengan mudah mengelola sistem pembelajaran, memonitor
                      proses pembelajaran dan perkembangan aktifitas pembelajaran di Scola LMS.</p>
                    <div class="d-block mb-3">
                      <div class="row justify-content-center">
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Kelola Pengguna (Siswa, Guru, Orang Tua)</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Kelola Kelas & Rombel</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Kelola Rencana Pembelajaran</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Kelola Kurikulum</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Monitoring Kontent Guru</div>
                            </li>
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Kelola Topik & Subtopik</div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul class="feature-list-check">
                            <li>
                              <span class="fe fe-check-circle mr-2"></span>
                              <div>Laporan Pembelajaran</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <router-link to="manajemen-sistem"
                      class="btn btn-primary btn-primary-3d btn-push text-xs d-block-mobile">Pelajari Sistem Manajemen<span
                        class="fe fe-arrow-right ml-2"></span></router-link>
                  </div>

                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      
    </div>
</template>

<script>
  // Slck
  import VueSlickCarousel from 'vue-slick-carousel'

  export default {
    name: 'FeatureList',
    components: {
      VueSlickCarousel
    }
  }
</script>